@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

.py-6 {
  padding: 100px 0px;
}

.btn:focus,
.form-control:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* overflow-x: hidden !important; */
}

html {
  scroll-behavior: smooth;
}

a:hover {
  text-decoration: none;
}

.navbar {
  z-index: 15;
}

.dropdown-item {
  color: #fff;
  font-size: 10px;
}

.nav-link {
  font-size: 12px;
}

.btn-hover-black:hover {
  background: black;
  color: #fff;
  border: none;
}

.btn-hero {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 2px;
  border: 1px solid #fff;
}
.btn-hero:hover {
  background: #fff;
  color: black;
}
/* Our purpose holder */
.our-purpose-holder {
  position: relative;
  background: url("./assets/img/earth.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
}

.our-purpose-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 60vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
}

.hero {
  background: url("./assets/img/hero-bg1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 70vh;
  position: relative;
  overflow-x: hidden;
}
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 70vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.85);
}

.banner img {
  width: 100%;
  height: 100%;
}

/* Church online component style */
.church-online::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.45;
}

.church-online > * {
  z-index: 10;
  position: relative;
}

.church-online {
  background: url("./assets/img/guitarforchurchonline.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: relative;
}

.church-online-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

/* Footer style */
.footer-message {
  color: #fff;
  background-color: black;
  padding: 10px;
  letter-spacing: 1px;
}

.social-media {
  display: flex;
  justify-content: space-around;
  font-size: 2.5rem;
}

.social-icons {
  background: #151815;
}

/* Contact us Component style */
.contact::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #333;
  opacity: 0.85;
}

.contact > * {
  z-index: 10;
  position: relative;
}

.contact {
  background: url("./assets/img/contact-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0;
}

.card-form {
  opacity: 0.7;
}

/* Our believe component style */
.believe > * {
  z-index: 10;
  position: relative;
}

.believe::after {
  content: "";
  position: absolute;
  height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  background: black;
  display: block;
}

.believe {
  background: url("./assets/img/ourbelieve-bible.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 70vh;
  position: relative;
  z-index: 0;
}

/* Given page style */
.giving {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url("./assets/img/giving.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 70vh;
  position: relative;
  z-index: 0;
}

.giving::after {
  content: "";
  position: absolute;
  height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  background: black;
  display: block;
}

.giving > * {
  z-index: 10;
  position: relative;
}

/* Kids component style */
.kids {
  background: url("./assets/img/kids-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
}
.kids-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
}

@media (max-width: 600px) {
  body {
    overflow-x: hidden !important;
  }
  .hero-content h4 {
    font-size: 2rem;
  }

  .hero {
    height: 140vh;
  }
  .hero-overlay {
    height: 140vh;
  }
  .believe,
  .believe::after {
    height: 85vh;
    padding: 50px 0;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 150vh;
  }
  .hero-overlay {
    height: 150vh;
  }
}
